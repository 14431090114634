<template>
  <div class="m-4">
    <h2>Privacy Policy</h2>
    <p>Effective date: July 29 2022</p>

    <h5>1. Introduction</h5>
    <p>
      Welcome to Jukebox Tools. We are committed to protecting your personal
      information and your right to privacy. This Privacy Policy applies to all
      information collected through our website.
    </p>

    <h5>2. Information We Collect</h5>
    <p>
      We collect personal information that you voluntarily provide to us when
      registering on the Website, expressing an interest in obtaining
      information about us or our products and services, or otherwise contacting
      us.
    </p>

    <h5>3. How We Use Your Information</h5>
    <p>
      We use personal information collected via our Website for a variety of
      business purposes described below:
    </p>
    <ul>
      <li>To facilitate account creation and logon process.</li>
      <li>To send administrative information to you.</li>
      <li>To fulfill and manage your data.</li>
      <li>To request feedback.</li>
      <li>To protect our Services.</li>
    </ul>
    <br />

    <h5>4. Will Your Information Be Shared With Anyone?</h5>
    <p>
      We only share information with your consent, to comply with laws, to
      protect your rights, or to fulfill business obligations.
    </p>

    <h5>5. Cookies and Other Tracking Technologies</h5>
    <p>
      We may use cookies and similar tracking technologies to access or store
      information.
    </p>

    <h5>6. Third-Party Services</h5>
    <p>
      Our Website may contain advertisements from third parties that are not
      affiliated with us and which may link to other websites, online services,
      or mobile applications.
    </p>

    <h5>7. Keeping Your Information Safe</h5>
    <p>
      We have implemented appropriate technical and organizational security
      measures designed to protect the security of any personal information we
      process.
    </p>

    <h5>8. Privacy Rights</h5>
    <p>You may review, change, or terminate your account at any time.</p>

    <h5>9. Updates to This Policy</h5>
    <p>We may update this privacy policy from time to time.</p>

    <h5>10. Contact Us</h5>
    <p>
      If you have questions or comments about this policy, you may contact our
      Data Protection Officer (DPO) at <a href="mailto:privacy@jukebox.tools">privacy@jukebox.tools</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "privacy",
};
</script>
